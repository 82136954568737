import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const sessionExpiry = localStorage.getItem('sessionExpiry');
    const isSessionValid = token && sessionExpiry && new Date().getTime() < parseInt(sessionExpiry, 10);

    if (!isSessionValid) {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionExpiry');
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute;
