import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/profile.css";
import authService from "../services/authService";

const Profile = () => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfJoining: "",
    userCredit: "",
    userPoints: "",
    cardNumber: "",
    mobile: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editableProfile, setEditableProfile] = useState({ ...profile });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await authService.getProfile(token);
        setProfile(response.data);
        setEditableProfile(response.data);
      } catch (error) {
        setMessage(error.response?.data?.message || "Error fetching profile");
      }
    };

    fetchProfile();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableProfile({ ...editableProfile, [name]: value });
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    try {
      await authService.updateProfile(token, editableProfile);
      setProfile(editableProfile);
      setIsEditing(false);
      setMessage("Profile updated successfully");
      setTimeout(() => {
        setMessage("");
      }, 3000);
    } catch (error) {
      setMessage(error.response?.data?.message || "Error updating profile");
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("sessionExpiry");
    navigate("/");
  };

  return (
    <div className="profile-container">
      {message && (
        <div
          className={`message ${
            message.includes("successfully") ? "success" : "error"
          }`}
        >
          {message}
        </div>
      )}
      {!isEditing ? (
        <div className="profile-details">
          <h1>Profile</h1>
          <p>
            <strong>First Name:</strong> {profile.firstName}
          </p>
          <p>
            <strong>Last Name:</strong> {profile.lastName}
          </p>
          <p>
            <strong>Email:</strong> {profile.email}
          </p>
          <p>
            <strong>Date of Joining:</strong> {profile.dateCreated?.split('T')[0]}
          </p>
          <p>
            <strong>User Credit:</strong> {profile.userCredit}
          </p>
          <p>
            <strong>User Points:</strong> {profile.userPoints}
          </p>
          <p>
            <strong>Card Number:</strong> {profile.cardNumber}
          </p>
          <p>
            <strong>Mobile:</strong> {profile.mobile}
          </p>
          <button onClick={handleEdit}>Edit Profile</button>
          <button disabled className="button-disabled">
            Charge Credit
          </button>
          <button disabled className="button-disabled">
            Cashout
          </button>
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <div className="profile-details">
          <h1>Edit Profile</h1>
          <label>
            First Name:
            <input
              type="text"
              name="firstName"
              value={editableProfile.firstName || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="lastName"
              value={editableProfile.lastName || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={editableProfile.email || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Mobile:
            <input
              type="text"
              name="mobile"
              value={editableProfile.mobile || ""}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Card Number:
            <input
              type="text"
              name="cardNumber"
              value={editableProfile.cardNumber || ""}
              onChange={handleInputChange}
            />
          </label>
          <button onClick={handleSave}>Save Changes</button>
          <button onClick={() => setIsEditing(false)}>Back to Profile</button>
        </div>
      )}
    </div>
  );
};

export default Profile;
