import axios from "axios";

const API_URL = "https://atlasmana.com/dev";

const authService = {
  login: async (email, password) => {
    return await axios.post(`${API_URL}/login`, { email, password });
  },
  verifyOtp: async ({ userId, otp }) => {
    return await axios.post(`${API_URL}/login/verify-otp`, { userId, otp });
  },
  getProfile: async (token) => {
    return await axios.get(`${API_URL}/profile`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateProfile: async (token, profileData) => {
    return await axios.put(`${API_URL}/profile/edit`, profileData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export default authService;
