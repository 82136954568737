import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import '../styles/verify.css';

const VerifyOtp = () => {
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(120);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(countdown);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                setError('User ID is missing. Please login again.');
                return;
            }
            const response = await authService.verifyOtp({ userId, otp });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                const expiryTime = new Date().getTime() + 3600 * 1000;
                localStorage.setItem('sessionExpiry', expiryTime.toString());
                localStorage.removeItem('userId');
                navigate('/profile');
            } else {
                setError('Invalid OTP or verification failed.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Verification failed. Please try again.');
        }
    };

    return (
        <div className="verify-container">
            <form onSubmit={handleSubmit} className="verify-form">
                <h1 className="verify-title">SMS Verification</h1>
                {error && <p className="verify-error">{error}</p>}
                <input
                    type="text"
                    maxLength="4"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="verify-input"
                    placeholder="Enter 4-digit code"
                    required
                />
                <button type="submit" className="verify-button" disabled={timer === 0}>
                    Verify
                </button>
                <p className="verify-timer">Time remaining: {timer}s</p>
            </form>
        </div>
    );
};

export default VerifyOtp;
